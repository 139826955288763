<template>
  <div class="box-datalist-three">
      <ul class="box-title">
        <li v-for="(item, idx) in caption">
          <div class="tit"><i>{{item.title}}</i></div>
          <div v-if="item.subs" class="subTitle">
            <span v-for="(sub,index) in item.subs"
              :style="{background: sub == curTab ? bgColors[index-1] : ''}"
              :class="sub == curTab ? 'sel' : ''" :key="index">
              <i v-if="idx == caption.length-1"
                @click="selTab(sub, index)">{{sub}} <i class="triangle-down"></i>
              </i>
              <i v-else>{{sub}}</i>
            </span>
          </div>
        </li>
      </ul>

      <div v-if="curTab" class="box-detail">
        <div class="label">
          <a style="visibility: hidden;">-</a>
          <span v-for="(label, idx) in labelList">
            <span class="item" v-for="(item, i) in label" :key="item.text + idx">
              <strong :style="{background: getLabelBgColor(idx)}">{{item.text}}</strong>
            </span>
          </span>
        </div>
        <div class="list">
          <p class="" v-for="(item, idx) in detailList">
            <a @click="jumpTo({id: item.area_ids})">{{(idx+1) + item.area_name}}</a>
            <fragment v-if="item.data">
            <span v-for="(l, i1) in item.data" :key="i1">
              <span class="item" v-for="(l, i2) in l.data" :key="i2"
              :style="{color:getFtColor(l['3_tags'])}">
                {{l['3_tags']}}
              </span>
            </span>
            </fragment>
          </p>
        </div>
      </div>

      <ul v-else class="box-item" v-for="(item, idx) in scoreList">
        <li class="name" @click="jumpTo(item)"><strong>{{idx + 1}} {{item.name}}</strong></li>
        <li v-for="(score, i) in item.dataList">
          <strong class="item" v-for="(d) in score">
            <span class="score btn" v-if="Array.isArray(d)" @click="showPop(d)">{{d[0]}}</span>
            <span class="score" v-else>{{d}}</span>
          </strong>
        </li>
      </ul>

      <el-dialog
        title=" "
        :visible.sync="popScore"
        class="pop-score"
        >
        <table class="tbl-score" border="0" cellspacing="0" cellspadding="0">
          <tbody>
            <tr>
              <th colspan="2">系统使用得分</th>
            </tr>
            <tr>
              <th>人员使用得分
                <span>(有录音的人数/有客流的人数)</span>
              </th>
              <th>录音匹配得分
                <span>(已匹配录音数/录音总数)</span>
              </th>
            </tr>
            <tr>
              <td v-if="systemData[1]">
                {{systemData[1][0]}}<span>({{systemData[1][1]}}/{{systemData[1][2]}})</span>
              </td>
              <td v-if="systemData[2]">
                {{systemData[2][0]}}<span>({{systemData[2][1]}}/{{systemData[2][2]}})</span>
              </td>
            </tr>
          </tbody>        
        </table>
        <span slot="footer" class="dialog-footer">
        </span>
      </el-dialog>
  </div>
</template>

<script>
import { jump } from "@/dbCommon/utils/index";

export default {
  name: 'TwoLevelList',
  props: {
    tableData: {},
    nextPage: '',
    queryKey: '',
    tableQualityData: {
      type: Array,
      default() {
        return [];
      }
    },
    
  },
  watch: {
    tableData({caption=[], scoreList=[]}) {
      this.caption = caption;
      this.scoreList = scoreList;
    }
  },
  data() {
    return {
      caption: [],
      scoreList: [],
      systemData: [],
      popScore: false,
      curTab: '',
      cIdx: 0,
      bgColors: ['#d59ce7', '#377EEA', '#FAC15C', '#11C16F', '#ED5A54', '#969799'],
      
    };
  },
  methods: {
    getLabelBgColor(idx) {
      if (this.curTab == '总分') {
        return this.bgColors[idx];
      }
      return this.bgColors[this.cIdx - 1];
    },
    selTab(v, cIdx) {
      console.log(v);
      this.curTab = this.curTab == v ? '' : v;
      this.cIdx = cIdx;

      const {labelList, detailList} = formatData(this.getTabData(v));
      this.detailList = detailList;
      this.labelList = labelList;
    },
    getTabData(v) {
      const list = [...this.tableQualityData].map(o => {
        if (v == '总分') {
          return {...o};
        }
        const data = (o.data || []).filter(d => (d.name == v));
        return { ...o, data }
      });
      return list;
    },
    getFtColor(v) {
      if (v < 60 || v === '-') {
         return '#ED5A54';
      }
      if (v >= 80) {
        return '#11C16F';
      }

      return '#FAC15C';
    },
    
    showPop(d) {
      this.popScore = true;
      this.systemData = d;
    },
    jumpTo(item) {
      if (!this.nextPage) {
        return;
      }
      const query={...this.$store.state.queryDB,[this.queryKey]:item.id};
      jump(this.nextPage,this, query);
    },
  },
}

function formatData(list = []) {
  const l = (list[0] || {}).data || [];
  const labelList = l.reduce((s, o) => {
    return [...s, o.data]
  }, []);
  return {labelList, detailList: [...list]};
}

</script>

<style scoped lang="less">
.box-datalist-three {
  border-radius: 2px;
  background: #EDEEEE;
  .box-title{
    font-weight: bold;
    background: #C8C9CC;
    display: flex;
    align-items: stretch;
    color: #000;
    line-height: 1.3;
    li {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &:first-child {
        .tit {
          border-left: 0;
        }
      }

      .tit {
        width: 100%;
        flex: 1;
        padding: 0.1rem 0;
        border-left: 1px solid #969799;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
      }

      .subTitle {
        flex: 1;
        display: flex;
        width: 100%;
        border-top: 1px solid #969799;
        span {
          box-sizing: border-box;
          border-left: 1px solid #969799;
          display: flex;
          align-items: center;
          position: relative;
          padding-bottom: 0.25rem;

          &:after {
            content: ' ';
            position: absolute;
            bottom: 0.07rem;
            left: 50%;
            width: 4px;
            height: 4px;
            border-left: 1px solid #000;
            border-top: 1px solid #000;
            transform: translateX(-50%) rotate(45deg);
          }

          &.sel {
            background: #0070C0;
            color: #fff;
            &:after {
              border: 0;
              border-right: 1px solid #fff;
              border-bottom: 1px solid #fff;
              transform: translate(-50%, -50%) rotate(45deg);
            }
          }
          

          &:first-child {
            min-width: 2em;
            flex: 1;
          }
          i {
            width: 200%;
          }
        }
      }
      i {
        font-size: 0.32rem;
        transform: scale(0.76);
        display: block;
        font-style: normal;
      }
    }
  }
  .box-item{
    display: flex;
    color: #1D1D1B;
    align-items: center;
    border-bottom: 1px solid #C3D5E4;
    padding: 0.1rem 0;

    li { 
      display: flex;
      align-items: center;

      &.name {
        overflow: hidden;

        strong {
          font-size: 0.32rem;
          display: block;
          transform: scale(0.83);
          width: 200%;
          text-align: left;
          word-break: break-all;
        }
      }
      .score.btn {
        color: #377EEA;
        text-decoration: underline;
      }
      .item {
        flex: 1;
        font-size: 0.26666rem;
      }
    }

     .score{
      display: block;
      border-radius: 20px;
      width: 80%;
      max-width: 50px;
      overflow: hidden;
     }

  }
  li {
    flex: 1;
    list-style: none;
    text-align: center;
    &:first-child {
      max-width: 1.17333rem;
      min-width: 1.17333rem;
    }
    &:nth-child(2), &:nth-child(3) {
      max-width: 0.8rem;
      min-width: 0.9rem;
      i {
      }
    }
    &:nth-child(4) {
    }
  }

  .box-detail {
    display: flex;
    background: #F7F8FB;
    >div {
      flex: 1;
      text-align: center;

      &.list {
        display: flex;
        overflow: scroll;

        p {
          flex: 1;
          text-align: center;
        }
      }

      &.label {
        max-width: 3.5rem;
        .item {
          line-height: 0.49333rem;
          padding-top: 0.08rem;
        }
        strong{
          display: block;
          color: #fff;
        }
      }

      .item {
        font-weight: bold;
        display: block;
        border-top: 1px solid #EAEEF4;
        white-space: nowrap;
        height: 0.69333rem;
        line-height: 0.69333rem;
        overflow:hidden;
      }
      a {
        padding: 0 0.2rem;
        height: 0.69333rem;
        line-height: 0.69333rem;
        overflow:hidden;
        color: #1D3456;
        display: block;
        white-space: nowrap;
      }
    }
  }
  
}

.tbl-score {
  width: 100%;
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
  th, td {
    border-left: 1px solid #ddd;
    border-top: 1px solid #ddd;
    padding: 5px 0;
    text-align: center;
    width: 50%;
  }

  th {
    span {
      font-size: 0.32rem;
      display: block;
    }
  }
}
</style>

        
