<template>
  <div class="box-datalist-dts">
      <ul class="box-title">
        <li v-for="(item, idx) in caption">
          <div class="tit"><i>{{item.title}}</i></div>
          <div v-if="item.subs" class="subTitle">
          <span :class="'item i' + index" v-for="(sub,index) in item.subs">
            <i>{{sub}}</i>
          </span>
          </div>
        </li>
      </ul>
      <ul class="box-item" v-for="(item, idx) in scoreList">
        <fragment v-if="!item.id">
        <li class="name"><span class="strong">{{item.name}}</span></li>
        <li v-for="(score, i) in item.dataList">
          <span :class="'item i' + index" v-for="(d, index) in score">
            <span class="score" v-if="Array.isArray(d)">{{d[0]}}</span>
            <span class="score" v-else>{{d}}</span>
          </span>
        </li>
        </fragment>
        <fragment v-else>
        <li class="name" @click="jumpTo(item)"><a class="strong">{{idx}} {{item.name}}</a></li>
        <li v-for="(score, i) in item.dataList">
          <strong :class="'item i' + index" v-for="(d, index) in score">
            <span class="score" v-if="Array.isArray(d)">{{d[0]}}</span>
            <span class="score" v-else>{{d}}</span>
          </strong>
        </li>
        </fragment>
      </ul>
      <el-dialog
        title=" "
        :visible.sync="popScore"
        class="pop-score"
        >
        <table class="tbl-score" border="0" cellspacing="0" cellspadding="0">
          <tbody>
            <tr>
              <th colspan="2">系统使用得分</th>
            </tr>
            <tr>
              <th>人员使用得分
                <span>(有录音的人数/有客流的人数)</span>
              </th>
              <th>录音匹配得分
                <span>(已匹配录音数/录音总数)</span>
              </th>
            </tr>
            <tr>
              <td v-if="systemData[1]">
                {{systemData[1][0]}}<span>({{systemData[1][1]}}/{{systemData[1][2]}})</span>
              </td>
              <td v-if="systemData[2]">
                {{systemData[2][0]}}<span>({{systemData[2][1]}}/{{systemData[2][2]}})</span>
              </td>
            </tr>
          </tbody>        
        </table>
        <span slot="footer" class="dialog-footer">
        </span>
      </el-dialog>
  </div>
</template>

<script>
import { jump } from "@/dbCommon/utils/index";

export default {
  name: 'TwoLevelListDTS',
  props: {
    tableData: {},
    nextPage: '',
    queryKey: '',
  },
  watch: {
    tableData({caption=[], scoreList=[]}) {
      this.caption = caption;
      this.scoreList = scoreList;
    }
  },
  data() {
    return {
      caption: [],
      scoreList: [],
      systemData: [],
      popScore: false,
    };
  },
  methods: {
    showPop(d) {
      this.popScore = true;
      this.systemData = d;
    },
    jumpTo(item) {
      if (!this.nextPage || !item.id) {
        return;
      }
      const query={...this.$store.state.queryDB,[this.queryKey]:item.id};
      jump(this.nextPage,this, query);
    },
  },
}
</script>

<style scoped lang="less">
.box-datalist-dts {
  border-radius: 2px;
  background: #EDEEEE;
  .box-title{
    font-weight: bold;
    background: #C8C9CC;
    display: flex;
    align-items: stretch;
    color: #000;
    line-height: 1.3;
    li {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &:first-child {
        .tit {
          border-left: 0;
        }
      }

      .tit {
        width: 100%;
        flex: 1;
        padding: 0.1rem 0;
        border-left: 1px solid #969799;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
      }

      .subTitle {
        flex: 1;
        display: flex;
        width: 100%;
        border-top: 1px solid #969799;
        span {
          box-sizing: border-box;
          border-left: 1px solid #969799;
          display: flex;
          flex: 1;
          /*
          align-items: center;
          */

          i {
            width: 200%;
          }
        }
      }
      i {
        font-size: 0.32rem;
        transform: scale(0.76);
        display: block;
        font-style: normal;

        .strong {
          line-height: 1.1;
          display: block;
          transform: scale(0.83);
          max-width: 0.9rem;
        }
      }
    }
  }
  .box-item{
    display: flex;
    color: #1D1D1B;
    align-items: center;
    border-bottom: 1px solid #C3D5E4;
    padding: 0.1rem 0;
    margin: 0;

    li { 
      display: flex;
      align-items: center;

      &.name {
        overflow: hidden;

        a {
          color: #377EEA;
          font-weight: bold;
        }
        .strong {
          font-size: 0.32rem;
          display: block;
          transform: scale(0.83);
          width: 200%;
          text-align: left;
          word-break: break-all;
        }
      }
      .score.btn {
        color: #377EEA;
        text-decoration: underline;
      }
      .item {
        flex: 1;
        font-size: 0.26666rem;
      }
    }

     .score{
      display: block;
      border-radius: 20px;
      max-width: 50px;
      overflow: hidden;
     }

  }
  li {
    flex: 1;
    list-style: none;
    text-align: center;
    &:first-child {
      max-width: 1.17333rem;
    }
    &:nth-child(2) {
      max-width: 1rem;
    }
    &:last-child {
    }
  }
}

.tbl-score {
  width: 100%;
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
  th, td {
    border-left: 1px solid #ddd;
    border-top: 1px solid #ddd;
    padding: 5px 0;
    text-align: center;
    width: 50%;
  }

  th {
    span {
      font-size: 0.32rem;
      display: block;
    }
  }
}
</style>
